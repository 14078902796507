import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { sessionDetailParams } from 'api';
import { useMixpanel } from 'contexts/mixpanel-context';
import { useStorage } from 'contexts/storage-context';
import { useStore } from 'contexts/store-context';
import { useToast } from 'contexts/toast-context';
import { useQuery } from 'hooks';
import * as LocalStorage from 'local-storage';
import * as logger from 'utils/logger';

type iSessionContext = {
	user?: any;
	loading: boolean;
	isAuthenticated: () => boolean;
	rootApi: string;
	rootLink: string;
	onAuth: any;
	onUpdate: any;
	onRefresh: any;
	onLogout: any;
	loaded?: any;
};

const storageKey = process.env.NEXT_PUBLIC_STORAGE_KEY;

const SessionContext = React.createContext<iSessionContext>(null);

const SessionProvider = ({ children }) => {
	const [user, setUser] = useState<any>({});
	const [loaded, setLoaded] = useState(false);
	const mixpanel = useMixpanel();
	const router = useRouter();
	const storage = useStorage();
	const store = useStore();
	const toast = useToast();

	// Computed
	const rootApi = `/session`;
	const rootLink = `/${user.uuid}`;
	const isAuthenticated = () => (user.id ? true : false);

	// Hooks
	useEffect(() => {
		const token = storage.getValue('token');
		token?.id ? fetchData() : onClear();
	}, []);

	useEffect(() => {
		window.addEventListener('storage', onApply);
		return () => window.removeEventListener('storage', onApply);
	}, [user.id]);

	useEffect(() => {
		if (user.id) logger.debug('sessionContext: ', user);
	}, [user]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		try {
			const response = await store.queryRecord({ url: `/session`, params: sessionDetailParams });
			mixpanel.identify(response.data);
			mixpanel.trackEvent('Session', response.data);
			storage.storeValues({ session: response.data });
			setUser(response.data);
			setLoaded(true);
		} catch (e) {
			onLogout();
		}
	});

	// Methods
	const onApply = () => {
		const session = LocalStorage.get(`${storageKey}.session`) as any;
		if (session?.id && session?.id !== user.id) return setUser(session);
		if (!session?.id && user.id) return onLogout();
	};

	const onAuth = async user => {
		const headers = { Authorization: user.token?.token };
		const response = await store.queryRecord({ url: `/session`, headers, params: sessionDetailParams });
		mixpanel.identify(response.data);
		mixpanel.trackUser(response.data);
		storage.storeCookie('token', user.token?.token);
		storage.storeValues({ session: response.data, token: user.token });
		setUser(response.data);
	};

	const onUpdate = data => {
		setUser({ ...user, ...data });
		mixpanel.trackUser({ ...user, ...data });
	};

	const onRefresh = () => fetchData();

	const onClear = () => {
		storage.storeValues({ account: {}, session: {}, token: {} });
		storage.removeCookies(['account', 'accountType', 'token']);
		setLoaded(true);
	};

	const onLogout = (silent = false) => {
		if (silent) {
			setUser({});
			logger.debug('sessionContext: ', {});
			return storage.onClear();
		}
		router.push('/');
		setUser({});
		toast.showSuccess(200, `You've been logged out.`);
		logger.debug('sessionContext: ', {});
		return storage.onClear();
	};

	// Render
	return (
		<SessionContext.Provider
			value={{
				user,
				loading,
				isAuthenticated,
				rootApi,
				rootLink,
				onAuth,
				onUpdate,
				onRefresh,
				onLogout,
				loaded,
			}}>
			{children}
		</SessionContext.Provider>
	);
};

const useSession = () => {
	return React.useContext(SessionContext);
};

export { SessionProvider, useSession };

// Docs
// https://www.npmjs.com/package/local-storage
