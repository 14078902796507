import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { PageLoader } from '@playbooks/interface/loaders';
import { useIntercom } from 'contexts/intercom-context';
import { useQuery } from 'hooks';
import { timeout } from 'utils';
import * as logger from 'utils/logger';

type iAdmin = {
	admin: any;
	setAdmin: any;
	open: boolean;
	setOpen: any;
	accordions: string[];
	setAccordions: any;
	rootApi: string;
	rootLink: string;
	onToggle: any;
	onRefresh: any;
};

const AdminContext = React.createContext<iAdmin>(null);

const AdminProvider = ({ session, store, toast, children }) => {
	const [admin, setAdmin] = useState<any>({});
	const [open, setOpen] = useState(true);
	const [accordions, setAccordions] = useState(['marketplace', 'accounts', 'pipelines']);
	const intercom = useIntercom();
	const router = useRouter();

	// Computed
	const rootApi = ``;
	const rootLink = `/admin`;

	// Hooks
	useEffect(() => {
		if (intercom.loaded) intercom.shutdown();
		return () => intercom.activate();
	}, [intercom.loaded]);

	useEffect(() => {
		if (session.loaded) fetchData();
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		if (admin.id) logger.debug('adminContext: ', admin);
	}, [admin]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		try {
			await timeout(600);
			const response = await store.queryRecord({ url: `/session` });
			if (response.data.role !== 'admin') throw new Error(`Sorry, you're not authorized`);
			setAdmin(response.data);
		} catch (e) {
			router.push('/403');
		}
	});

	// Methods
	const onToggle = () => {
		setOpen(!open);
	};

	const onRefresh = () => fetchData();

	const onClear = () => setAdmin({});

	// Render
	return (
		<AdminContext.Provider
			value={{ admin, setAdmin, open, setOpen, accordions, setAccordions, rootApi, rootLink, onToggle, onRefresh }}>
			{admin.id ? children : <PageLoader title='Hang tight' message='Loading dashboard...' />}
		</AdminContext.Provider>
	);
};

const useAdmin = () => {
	return React.useContext(AdminContext);
};

export { AdminProvider, useAdmin };
