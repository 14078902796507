;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"e101d698e4d74694f454e8515eaf9e320d8c9583"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn: SENTRY_DSN,
	environment: process.env.NEXT_PUBLIC_NODE_ENV,
	tracesSampleRate: 1.0,
	enabled: process.env.NEXT_PUBLIC_NODE_ENV !== 'development',
});
