import React, { useEffect, useState } from 'react';

import { PageLoader } from '@playbooks/interface/loaders';
import { useIntercom } from 'contexts/intercom-context';
import { useQuery } from 'hooks';
import { timeout } from 'utils';
import * as logger from 'utils/logger';

type SubmitStackContext = {
	stack: any;
	setStack: any;
	rootApi: string;
	view: string;
	onView: any;
};

export const SubmitStackContext = React.createContext<SubmitStackContext>(null);

const SubmitStackProvider = ({ account, router, session, store, toast, children }) => {
	const [stack, setStack] = useState<any>({});
	const { stackId, view = 'basics' } = router.query;
	const intercom = useIntercom();

	// Computed
	const rootApi = `${account.rootApi}/stacks/${stackId}`;

	// Hooks
	useEffect(() => {
		intercom.shutdown();
		return () => intercom.activate();
	}, []);

	useEffect(() => {
		if (session.loaded) session.isAuthenticated() ? fetchData() : router.push('/login');
		return () => onClear();
	}, [session.loaded]);

	useEffect(() => {
		logger.debug('stackContext: ', stack);
	}, [stack]);

	// Actions
	const [fetchData, loading, error] = useQuery(async () => {
		await timeout(600);
		const response = await store.queryRecord({
			url: rootApi,
			params: {
				include: ['bounties', 'languages', 'frameworks', 'platforms', 'repos', 'tools', 'topics'],
			},
		});
		setStack(response.data);
	});

	useEffect(() => {
		if (error) router.push('/403');
	}, [error]);

	// Methods
	const onView = view => {
		router.push(`/add/stack/${stack.uuid}?view=${view}`);
		window.scrollTo(0, 0);
	};

	const onClear = () => {
		setStack({});
	};

	// Render
	return (
		<SubmitStackContext.Provider value={{ stack, setStack, rootApi, view, onView }}>
			{stack.id ? children : <PageLoader title='Hang tight' message='Loading your workflow...' />}
		</SubmitStackContext.Provider>
	);
};

const useSubmitStack = () => {
	return React.useContext(SubmitStackContext);
};

export { SubmitStackProvider, useSubmitStack };
